
.spinner {
	background-color: rgba(0, 0, 0, 0.213); /* sets the background to transparent black */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    z-index: 1;
}
.spinner span {
    display: block !important;
}

