.input-group {
	flex-wrap: nowrap;
	height: 30px;
	align-items: center;
	width: 100%;
}
.btn-primary-search {
	color: var(--secondaryColor);
	height: 30px;
	margin: 0;
}
.btn-primary-search:hover {
	text-shadow: none;
}
.form-control {
	background: #e6e6e6;
	height: 30px;
	border: none;
	border-bottom: var(--secondaryColor) 2px solid !important;
}

.form-control:active {
	border: transparent;
}
input:focus,
select:focus,
select,
input.form-control:focus {
	outline: none !important;

	outline-width: 0 !important;

	box-shadow: none;

	-moz-box-shadow: none;

	-webkit-box-shadow: none;
}
