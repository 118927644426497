.loading-box-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.5); /* sets the background to transparent black */
}

.loading-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.loading-table-box-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25vh;
}
